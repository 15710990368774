/* General Styling */
body {
    font-family: Arial, sans-serif;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  .account-statement-container {
    width: 95%;
    margin: 10px auto;
    /* border: 1px solid #000; */
    padding: 10px;
  }
  
  /* Header Section */
  .statement-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .statement-header h1 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .statement-header h2 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .statement-header p {
    margin: 0;
    font-size: 14px;
  }
  
  /* Table Styling */
  .statement-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .statement-table th,
  .statement-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }
  
  .statement-table th {
    background-color: #f2f2f2;
  }
  
  /* Footer Section */
  .statement-summary {
    text-align: right;
    margin-top: 20px;
  }
  
  .statement-summary p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .statement-summary span {
    font-weight: bold;
  }

  .closing-balance {
    font-weight: 600;
    font-size: 15px;
    text-align: end;
  }
  .total {
    font-weight: 600;
    font-size: 15px;
  }
  